import { PUBLIC_SESSION_ID_COOKIE_KEY } from 'shared/constants'

export default defineNuxtRouteMiddleware(async () => {
  if (process.client) {
    const { forceLoad } = useSession()
    const { getURL } = useURL()
    const loginRoute = getURL({ name: 'login' })
    const psidCookie = useCookie(PUBLIC_SESSION_ID_COOKIE_KEY)

    if (psidCookie.value) {
      const session = await forceLoad()

      if (!session.isRegistered) {
        return navigateTo(loginRoute, { redirectCode: 301 })
      }
    } else {
      return navigateTo(loginRoute, { redirectCode: 301 })
    }
  }
})
